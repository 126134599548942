import EventBus from "../events/EventBus";
function onImageLoaded(img) {
    if (img.parentElement.nodeName === 'PICTURE') {
        img.parentElement.classList.add('img--loaded');
    }
    else {
        img.classList.add('img--loaded');
    }
}
export function loadImage(img) {
    if (img.getAttribute('loading') && img.loading !== 'eager') {
        img.loading = 'eager';
    }
}
export function refreshLazyImages(root) {
    for (const img of (root !== null && root !== void 0 ? root : document).querySelectorAll('picture:not(.img--loaded) > img[loading], :not(picture) > img:not(.img--loaded)[loading]')) {
        handleLazyImage(img);
    }
}
export function handleLazyImage(img) {
    if (img.complete && img.naturalWidth) {
        onImageLoaded(img);
    }
    else {
        img.addEventListener('load', () => onImageLoaded(img));
    }
}
export function initLazyLoad() {
    EventBus.on('products:load', () => refreshLazyImages());
    EventBus.on('modal:open', (modal) => refreshLazyImages(modal));
}
